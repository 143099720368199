// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bq_kz";
export var caseStudyBackground__lineColor = "bq_kq";
export var caseStudyHead = "bq_kC";
export var caseStudyHead__imageWrapper = "bq_kn";
export var caseStudyProjectsSection = "bq_kB";
export var caseStudyQuote__bgLight = "bq_kx";
export var caseStudyQuote__bgRing = "bq_kw";
export var caseStudyVideo__ring = "bq_kF";
export var caseStudy__bgDark = "bq_km";
export var caseStudy__bgLight = "bq_kl";